<template>
    <table-view :total="total" :query-info.sync="queryInfo" @reload-table="renderTable">
        <template #header>
            <view-search-form :queryInfo.sync="queryInfo" @on-search="renderTable(1)" keyword-placeholder="项目名称"
                :tool-list="['keyword', 'gradeName', 'term']">
                <el-form-item label="部门" v-permission="['achievement:subject:all']">
                    <el-select v-model="queryInfo.deptId" clearable filterable size="small">
                        <el-option v-for="( item, index ) in  departmentList " :key="index" :label="item.deptName"
                            :value="item.id" />
                    </el-select>
                </el-form-item>
                <el-form-item label="项目类别">
                    <el-select v-model="queryInfo.projectType" clearable filterable size="small">
                        <el-option v-for="( item, index ) in  projectTypeList " :key="index" :label="item.type"
                            :value="item.type" />
                    </el-select>
                </el-form-item>
                <el-form-item label="横向课题">
                    <el-select v-model="queryInfo.horizontalCourseTopicFlag" clearable filterable size="small">
                        <el-option v-for="( item, index ) in  isHorizontalCourseTopicFlag " :key="index" :label="item.name"
                            :value="item.type" />
                    </el-select>
                </el-form-item>
            </view-search-form>
            <div class="header-button mb-1">
                <el-tag class="successNum">国家级:{{ count.countryLevel }};省级:{{ count.provinceLevel }};市级:{{
                    count.cityLevel }};校级:{{ count.schoolLevel }};</el-tag>
                <el-button @click="exportExcel()" type="success" size="small">
                    课题成果导出
                </el-button>
            </div>
        </template>
        <el-table :data="tableData" class=" mt-1" style="width: 100%" border stripe>
            <el-table-column type='index' label='ID' width='50' />
            <el-table-column prop="gradeName" min-width="100" label="学年"></el-table-column>
            <el-table-column prop="realName" label="姓名" width="70"></el-table-column>
            <el-table-column prop="teacherNo" label="工号"></el-table-column>
            <el-table-column prop="deptName" min-width="140" label="部门"></el-table-column>
            <el-table-column prop="term" label="学期">
                <template v-slot="{ row }">
                    {{ row.term ? `第${row.term} 学期` : '' }}
                </template>
            </el-table-column>
            <el-table-column prop="projectNo" label="项目编号"></el-table-column>
            <el-table-column prop="projectName" min-width="140" label="项目名称"></el-table-column>
            <el-table-column prop="projectType" label="项目类别">
                <template v-slot="{ row }">
                    <el-tag>{{ row.projectType }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="projectAmount" label="经费额度"></el-table-column>
            <el-table-column prop="beginMonth" min-width="100" label="开始日期"></el-table-column>
            <el-table-column prop="endMonth" min-width="100" label="结束日期"></el-table-column>
            <el-table-column prop="projectRole" label="本人角色"></el-table-column>
            <el-table-column prop="projectSelfRank" label="本人排名"></el-table-column>
            <el-table-column prop="representativeAchievementFlag" label="是否代表性成果" width="120">
                <template v-slot="{ row }">
                    <el-tag v-if="Number(row.representativeAchievementFlag) === 1" type="success">是</el-tag>
                    <el-tag v-else type="danger">否</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="horizontalCourseTopicFlag" label="是否横向课题" width="110">
                <template v-slot="{ row }">
                    <el-tag v-if="Number(row.horizontalCourseTopicFlag) === 1" type="success">是</el-tag>
                    <el-tag v-else type="danger">否</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="projectClient" label="项目委托单位" width="110"></el-table-column>
            <el-table-column label="操作" min-width="150" fixed="right">
                <template v-slot="{ row }">
                    <el-button type="text" @click="showDialog(row.id)">
                        查看详情
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <dialog-subject ref='dialogRef' :visible.sync='visibleDialog' v-if='visibleDialog' @on-close='renderTable' />
    </table-view>
</template>

<script>
import { pageSubjectApi, delSubjectApi, countSubjectApi } from '@/api/achievement/subject-api.js'
import { projectTypeList, isHorizontalCourseTopicFlag } from './components/options'
import dialogSubject from './components/DialogSubject.vue'
import tableView from '@/vue/mixins/table-view'
import store from '@/store/index'

export default {
    mixins: [tableView],
    components: {
        dialogSubject
    },
    data() {
        return {
            queryInfo: {
                startDate: null,
                endDate: null,
                deptId: null,
                gradeName: null,
                term: null,
                horizontalCourseTopicFlag: null,
                projectType: null,
            },
            projectTypeList,
            isHorizontalCourseTopicFlag,
            departmentList: '',
            formData: {
                id: '',
                projectRole: '',
                projectSelfRank: '',
                term: '',
                beginMonth: '',
                endMonth: '',
                projectType: '',
                representativeAchievementFlag: '',
                horizontalCourseTopicFlag: '',
                projectNo: '',
                projectName: '',
                projectClient: '',
                projectAmount: ''

            },
            count: {}
        }
    },
    mounted() {
        this.renderTable()
    },
    methods: {
        delSubjectApi,
        async renderTable(pageNum) {
            this.getAll()
            this.countSubjectApi()
            this.getDepartment()
            await this.getTableData(pageSubjectApi, pageNum)
        },
        // 统计
        countSubjectApi() {
            countSubjectApi(this.queryInfo).then((res) => {
                this.count = res.data
            })
        },
        // 查看全部的权限
        getAll() {
            const _permission = store.getters['login/getPermission']
            if (!(_permission.includes('achievement:subject:all'))) {
                this.queryInfo.deptId = this.$store.state.login.userInfo.deptId
            }
        },
        // 获取部门
        getDepartment() {
            this.$http.getDepartmentListQuery({}).then((res) => {
                this.departmentList = res.data
            })
        },
        // 导出
        exportExcel() {
            this.$http.exportExcelHttp.exportSubject(this.queryInfo)
        }
    }

}
</script>

<style lang="scss" scoped>
.successNum {
    margin-right: 20px;
}
</style>
