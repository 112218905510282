// 课题类型
export const projectTypeList = [
    { type: '校级' },
    { type: '市级' },
    { type: '省级' },
    { type: '国家级' }
]
// 是否代表性成果
export const isRepresentativeAchievementFlag = [
    { type: 1, name: '是' },
    { type: 0, name: '否' }
]
// 是否横向课题
export const isHorizontalCourseTopicFlag = [
    { type: 1, name: '是' },
    { type: 0, name: '否' }
  ]
