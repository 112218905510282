<template>
    <el-dialog class='grade-dialog' :title='`课题详情`' :visible.sync='show' :close-on-click-modal='false' width='1000px'
        :before-close='cancel'>
        <el-form ref='form' :model='formData' :rules='rules' label-width='130px'>
            <el-divider content-position="left">
                <h3>个人信息</h3>
            </el-divider>
            <el-row>
                <el-col :span='16'>
                    <el-form-item label="姓名：" prop="userId">
                        {{ formData.realName }}
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='8'>
                    <el-form-item label='部门：' prop='deptName'>
                        {{ formData.deptName }}
                    </el-form-item>
                </el-col>
                <el-col :span='8'>
                    <el-form-item label='工号：' prop='teacherNo'>
                        {{ formData.teacherNo }}
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='8'>
                    <el-form-item label='本人角色：' prop='projectRole'>
                        {{ formData.projectRole }}
                    </el-form-item>
                </el-col>
                <el-col :span='8'>
                    <el-form-item label='本人排名：' prop='projectSelfRank'>
                        {{ formData.projectSelfRank }}
                    </el-form-item>
                </el-col>
            </el-row>
            <el-divider content-position="left">
                <h3>课题信息</h3>
            </el-divider>
            <el-row>
                <el-col :span='8'>
                    <el-form-item label='学年：' prop='gradeName'>
                        {{ formData.gradeName }}
                    </el-form-item>
                </el-col>
                <el-col :span='8'>
                    <el-form-item label='学期：' prop='term'>
                        第{{ formData.term }}学期
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='16'>
                    <el-form-item label='开始/结束：' prop='beginMonth'>
                        {{ formData.beginMonth }} - {{ formData.endMonth }}

                    </el-form-item>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span='24'>
                    <el-form-item label='项目类别：' prop='projectType'>
                        {{ formData.projectType }}
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='8'>
                    <el-form-item label='是否代表性成果：' prop='representativeAchievementFlag'>
                        {{ Number(formData.representativeAchievementFlag) === 1 ? '是' : '否' }}
                    </el-form-item>
                </el-col>
                <el-col :span='8'>
                    <el-form-item label='是否横向课题：' prop='horizontalCourseTopicFlag'>
                        {{ Number(formData.horizontalCourseTopicFlag) === 1 ? '是' : '否' }}
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='8'>
                    <el-form-item label='项目编号：' prop='projectNo'>
                        {{ formData.projectNo }}
                    </el-form-item>

                </el-col>
                <el-col :span='8'>
                    <el-form-item label='项目名称：' prop='projectName'>
                        {{ formData.projectName }}
                    </el-form-item>
                </el-col>
                <el-col :span='8'>
                    <el-form-item label='项目委托单位：' prop='projectClient'>
                        {{ formData.projectClient }}
                    </el-form-item>
                </el-col>
                <el-col :span='8'>
                    <el-form-item label='经费额度：' prop='projectAmount'>
                        {{ formData.projectAmount }}
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </el-dialog>
</template>

<script>
import dialog from '@/vue/mixins/dialog'

import { getSubjectByIdApi } from '@/api/achievement/subject-api.js'

export default {
    name: 'gradeDialog',
    mixins: [dialog],
    data() {
        return {
            id: '',
            formData: {
                id: '',
                userId: '',
                realName: '',
                deptId: '',
                deptName: '',
                teacherNo: '',
                projectRole: '',
                projectSelfRank: '',
                gradeName: '',
                term: '',
                beginMonth: '',
                endMonth: '',
                projectNo: '',
                projectName: '',
                projectClient: '',
                projectAmount: '',
                beginDate: '', // 开始时间
                endDate: '', // 结束时间
                remarks: '',
                userType: 3,
                projectType: '校级', // 项目类别
                horizontalCourseTopicFlag: 0, // 是否横向课题
                representativeAchievementFlag: 0 // 是否代表性成果
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            if (this.id) {
                this.getDataById(this.id, getSubjectByIdApi)
            } else {
            }
        })
    },
    methods: {
    }
}
</script>
